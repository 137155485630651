<template>
  <div>
    <!-- <b-row>
      <b-col cols="12" xl="12">
        <app-filtro
          :nombre="nombre"
          :incluirInactivos="incluirInactivos"
          @filtrar="filtrar">
        </app-filtro>  
      </b-col>      
    </b-row> -->

    <b-row>
      <b-col cols="12" xl="12">  
        <transition name="slide">
        <b-card header="Listado de Características de Propiedades" >
          <b-table hover
              responsive="sm" 
              class="registro"
              :busy="tableBusy"
              :items="caracteristicasPropiedades" 
              :fields="itemFields"
              :current-page="paginaActual"
              :per-page="registrosPorPagina">
              <!-- @row-clicked="consultar"> -->
            <div slot="table-busy" class="text-center">
              <b-spinner class="table-spinner" label="Spinning" />
            </div>              

            <template slot="opciones" slot-scope="data">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template slot="button-content">
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click.stop="editarCaracteristicaPropiedad(data.item)">
                  <i class="fa fa-edit"></i>Editar Datos de la Característica</b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <nav>
            <b-pagination size="sm" 
              :total-rows="totalRegistros" 
              :per-page="registrosPorPagina" 
              v-model="paginaActual" prev-text="Prev." next-text="Prox."/>
          </nav>
        </b-card>
        </transition>
      </b-col>
    </b-row>

    <div class="fixed-btn">
      <div class="list-dropdown-btn">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template slot="button-content">
            <i class="fa fa-ellipsis-v fa-2x" aria-hidden="true"></i>
          </template>
          
          <b-dropdown-item @click.stop="agregarCaracteristicaPropiedad">
            <i class="fa fa-plus"></i>Agregar Característica</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <app-modal-msj
      :visible="modalMsjVisible"
      :mensaje="modalMensaje"
      :variant="modalVariant"
      @okAction="okAction"
      @hiddenAction="hiddenAction">
    </app-modal-msj>

    <app-modal-confirm
      :visible="confirmModalVisible"
      :mensaje="confirmMsj"
      :okTitle="confirmOkTitle"
      :cancelTitle="confirmCancelTitle"
      @confirmOk="confirmOk"
      @confirmCancel="confirmCancel">
    </app-modal-confirm>
  </div>
</template>

<script>
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "caracteristicasPropiedades/getField",
  mutationType: "caracteristicasPropiedades/updateField",
});

export default {
  name: 'CaracteristicasPropiedadesList',
  data () {
    return {
      itemFields: [
        // { key: 'titulo', _classes: 'font-weight-bold' },
        { key: 'nombre' },
        { 
          key: 'opciones',
          label: '',
          class: 'text-center'
        },
      ],
      
      tableBusy: false
    }
  },
  computed: {
    ...mapFields([
      'caracteristicasPropiedades',
      'paginaActual',
      'registrosPorPagina',
      'totalRegistros',
      'totalPaginas'
    ]),
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.tableBusy = true;
      this.$store.dispatch('caracteristicasPropiedades/getCaracteristicasPropiedades')
        .then(() => this.tableBusy = false)
        .catch(error => this.showErrorMsj(error));
    },
    agregarCaracteristicaPropiedad () {
      this.$router.push({ name: 'CaracteristicaPropiedad', 
                    params: { id: '' }});
    },
    editarCaracteristicaPropiedad (item) {
      this.$router.push({ name: 'CaracteristicaPropiedad', 
                    params: { id: item._id }});
    },
  },
}
</script>
